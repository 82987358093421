<template>
  <table-overview
    title="Buddies"
    store="buddies"
    :headers="[
      { text: 'Name', value: 'first_name' },
      { text: 'Email', value: 'email' },
      { text: 'Submissions', value: 'submission_count' },
      {
        text: 'Newsletter',
        value: 'is_newsletter',
        align: 'right',
        sortable: false
      }
      // { text: 'Actions', align: 'center', sortable: false, class: 'v-table-th--icon', rolesOrPermissions: 'buddies_view', },
    ]"
    :actions="actions"
    force-initial-sort
  >
    <buddy-table-row slot="table-row" slot-scope="{ item }" :buddy-id="item" />
  </table-overview>
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import buddiesModule from "@/store/modules/buddies";
import BuddyTableRow from "../../components/buddy/BuddyTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "buddies",
  buddiesModule
);

export default {
  name: "buddies-view",
  components: {
    BuddyTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "buddies.create" },
        rolesOrPermissions: "buddies_create"
      }
    ]
  }),
  ...overviewLifecycleMethods
};
</script>
