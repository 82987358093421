var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Buddies","store":"buddies","headers":[
    { text: 'Name', value: 'first_name' },
    { text: 'Email', value: 'email' },
    { text: 'Submissions', value: 'submission_count' },
    {
      text: 'Newsletter',
      value: 'is_newsletter',
      align: 'right',
      sortable: false
    } ],"actions":_vm.actions,"force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('buddy-table-row',{attrs:{"buddy-id":item}})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }